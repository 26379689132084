
import { defineComponent, inject } from "vue";
import { Database } from "vuebase";
import { ImageData } from "coho-ui";
import { Upload } from "../main";

export default defineComponent({
    name: "EditProducts",
    setup() {
        const database = inject(Database.InjectionKey);
        const uploads = database?.collection<Upload>("uploads").documents();

        function openImage(image: ImageData) {
            window.open(image.original, "_blank");
        }

        return {
            uploads,
            openImage,
        };
    },
});
