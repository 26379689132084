<template>
    <c-panel>
        <c-grid class="widths-50-sm widths-33-md widths-25-lg widths-25-xl">
            <template v-for="upload in uploads" :key="upload.id">
                <c-grid-item v-if="upload.data && upload.data.image" class="flex f-align-stretch">
                    <c-card @click="openImage(upload.data.image)">
                        <template #image v-if="upload.data?.image">
                            <c-image :image="upload.data.image" />
                        </template>
                        <template #body>
                            <p v-if="upload.data.email">{{ upload.data.email }}</p>
                            <p v-if="upload.data.notes">{{ upload.data.notes }}</p>
                        </template>
                    </c-card>
                </c-grid-item>
            </template>
        </c-grid>
    </c-panel>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { Database } from "vuebase";
import { ImageData } from "coho-ui";
import { Upload } from "../main";

export default defineComponent({
    name: "EditProducts",
    setup() {
        const database = inject(Database.InjectionKey);
        const uploads = database?.collection<Upload>("uploads").documents();

        function openImage(image: ImageData) {
            window.open(image.original, "_blank");
        }

        return {
            uploads,
            openImage,
        };
    },
});
</script>
